.container {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
  }
  
  .filter_container {
    height: auto;
    width: auto;
    display: flex;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 15px 20px;
    border-radius: 15px;
    flex-wrap: wrap;
  }
  
  @media screen and (max-width: 767px) {
    .filter_container {
      flex-direction: column;
    }
  }
  