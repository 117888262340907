.container{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
}
.card{
    height: auto;
    width: 300px;
}
@media (max-width:500px){
    .container{
        flex-direction: column;
        justify-content: center ;
        align-items: center;
    }
    .card{
      margin-bottom: 50px;
    }
    
}